import React from 'react';
import { Link } from 'react-scroll';

const VehicleSection = () => {

  return (
    <section id="services" className="bg-black py-24">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl underline font-bold text-orange-500 mb-8">Range of Vehicles</h2>
        <p className="text-gray-300 text-2xl mb-12">
          We have a wide range of well-maintained vehicles.
        </p>

        {/* Grid to display 4 cards in each row */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Vehicle Card 1 */}
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/fortuner.jpeg"
                alt="Tempo Traveller 16+1"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Fortuner 8+1</h3>
              <p className="text-gray-300">Rs. 20/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>16+1</span>
                <span>16 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 2 */}
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/ertiga.jpeg"
                alt="Ertiga (6+1)"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Ertiga (6+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>6+1</span>
                <span>6 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 3 */}
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/n.jpeg"
                alt="Tempo Traveller 20+1"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Scorpio N 8+1</h3>
              <p className="text-gray-300">Rs. 20/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>20+1</span>
                <span>20 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 4 */}
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/innova.png"
                alt="Innova (6+1)"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Innova (6+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>6+1</span>
                <span>6 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/dzire.jpeg" // replace with actual image path
                alt="DZire (4+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">DZire (4+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>4+1</span>
                <span>4 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 6 */}
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/scorpio.jpeg" // replace with actual image path
                alt="Scorpio (6+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Scorpio (6+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>6+1</span>
                <span>6 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/dzire.jpeg" // replace with actual image path
                alt="DZire (4+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">WgonR (4+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>4+1</span>
                <span>4 Seats</span>
                <span>Yes</span>
              </div>
              
            </div>
          </div>
          <div className="p-6">
            <div className="border border-orange-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/thar.jpeg" // replace with actual image path
                alt="DZire (4+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Thar (4+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-orange-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-orange-500 text-white p-2">
                <span>4+1</span>
                <span>4 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>


        </div>
        
      </div>
    </section>
    
  );
};

export default VehicleSection;
