import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll'; // Use react-scroll for smooth scrolling
import './App.css';
import VehicleSection from './vehicle';
import Navbar from './navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faCar, faMapMarkerAlt, faTools,faPhone , faWallet, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [navbarBg, setNavbarBg] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Change navbar background on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg('bg-black');
      } else {
        setNavbarBg('');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Navbar />

      <header
        id="home"
        className="relative bg-cover bg-center pt-20 h-[50vh] flex items-center justify-center text-center"
        style={{ backgroundImage: "url('/bg.jpg')" }}
      >
        <div className="container mx-auto text-white">
          <h1 className="text-5xl font-bold mb-4">Self Drive Car Ranchi</h1>
          <p className="text-lg mb-6">Experience Ranchi's Best Car Service</p>
          <Link
            to="footer"
            smooth={true}
            duration={500}
            className="bg-orange-600 text-white py-3 cursor-pointer px-6 rounded-lg hover:bg-orange-700 transition duration-300"
          >
            Book Now
          </Link>
        </div>
      </header>

      {/* Vehicle Section */}
      <div className="bg-black py-20">
        <section className="bg-black py-16">
          <div className="container mx-auto text-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
              {/* Well Maintained Cars */}
              <div className="flex flex-col items-center text-white">
                <FontAwesomeIcon icon={faCar} size="3x" className="text-orange-500 mb-4" />
                <h3 className="text-xl font-semibold">Well Maintained Cars</h3>
                <p className="mt-2 text-gray-300">Ensuring top-notch quality and safety.</p>
              </div>

              {/* Pick and Drop */}
              <div className="flex flex-col items-center text-white">
                <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" className="text-orange-500 mb-4" />
                <h3 className="text-xl font-semibold">Pick and Drop</h3>
                <p className="mt-2 text-gray-300">Convenient pick-up and drop-off services.</p>
              </div>

              {/* Privacy and Freedom */}
              <div className="flex flex-col items-center text-white">
                <FontAwesomeIcon icon={faThumbsUp} size="3x" className="text-orange-500 mb-4" />
                <h3 className="text-xl font-semibold">Privacy and Freedom</h3>
                <p className="mt-2 text-gray-300">Your journey, your rules.</p>
              </div>

              {/* Well Serviced */}
              <div className="flex flex-col items-center text-white">
                <FontAwesomeIcon icon={faTools} size="3x" className="text-orange-500 mb-4" />
                <h3 className="text-xl font-semibold">Well Serviced</h3>
                <p className="mt-2 text-gray-300">Regular maintenance for smooth rides.</p>
              </div>

              {/* Affordable Rates */}
              <div className="flex flex-col items-center text-white">
                <FontAwesomeIcon icon={faWallet} size="3x" className="text-orange-500 mb-4" />
                <h3 className="text-xl font-semibold">Affordable Rates</h3>
                <p className="mt-2 text-gray-300">Competitive pricing without compromising quality.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <VehicleSection />

      <section className="py-16 bg-black">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold text-orange-500 mb-8">Get in Touch</h2>
          <form className="max-w-md mx-auto border-2 border-orange-500 bg-gray-800 p-6 shadow-lg rounded-lg">
            <div className="mb-4">
              <label className="block text-left text-white font-semibold mb-2">Name</label>
              <input
                type="text"
                required
                className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left text-white font-semibold mb-2">Phone Number</label>
              <input
                type="text"
                pattern="\d{10}"
                required
                className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Your Phone Number"
              />
            </div>
            <button type="submit" className="bg-orange-600 text-white px-6 py-2 rounded-lg hover:bg-orange-700 transition duration-300">
              Get a call
            </button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer id="footer" className="bg-black text-white py-8">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h2 className="text-lg underline font-semibold">Self Drive Car Ranchi</h2>
            <h3 className="text-sm">Providing diverse transportation solutions since 2009.</h3>
            <p>Self Drive Car in Ranchi provides diverse transportation solutions in Ranchi, Jharkhand.</p>
          </div>

          <div className="text-center md:text-left">
            <p className="text-lg underline font-semibold">Contact Us</p>
            <p className="mt-2">Nawa Toli, Kamla Kant Ln, near Pahadi Mandir, Ranchi, Jharkhand 834001</p>
           {/* <p>Email: xyz@gmail.com</p> */}
           <div className="flex  space-x-2 mb-4">
          {/* Phone Icon */}
          <a
            href="tel:+919905503825"
            className="text-white hover:text-orange-600 flex items-center space-x-2"
            aria-label="Call us"
          >
            <FontAwesomeIcon icon={faPhone} size="lg" />
            <span className="text-gray-300">+91 99055 03825</span>
          </a>
        </div>
            <div className="flex justify-center md:justify-start space-x-4 py-4 mt-4 md:mt-0">
            <div className="flex justify-center items-center space-x-4 mb-4">
      <a 
        href="https://wa.me/9905503825" 
        className="text-white hover:text-orange-600 flex items-center space-x-2"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="xl" />
       
      </a>
    </div>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-orange-600 transition-colors duration-300">
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-orange-600 transition-colors duration-300">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>            
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
